export const messages = {
  auth: {
    error: "Error while authenticate with Slack",
    errorHangout: "Error while authenticate with your Google account",
    success: "Login success"
  },
  admin: {
    updated_admin: "Admins is updated",
    update_admin_error: "Error while update your admins, please try again!",
    updated_company_name: "Company name is updated",
    update_company_name_error:
      "Error while update your company name, please try again!",
    delete_admin_error: "Error while delete your admins, please try again!",
    deleted_admin: "Admin has been removed",
    err: "Error while get data"
  }
}
