import React, { Fragment } from "react"
import Routes from "src/routes/routes"
import "../assets/scss/index.scss"

function App() {
  return (
    <Fragment>
      <Routes />
    </Fragment>
  )
}

export default App
