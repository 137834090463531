import React from "react"
import { AppLoginContainer, LoginButton } from "./Login.styles"
import { GoogleLogin } from "react-google-login"
import { authService } from "src/services/auth.service"
import { useHistory } from "react-router-dom"
import { PATH } from "../../utils/constants/path.constants"
import { tokenService } from "../../services/token.service"
import { toastHelpers } from "../../utils/helpers/toast.helpers"
import { messages } from "../../utils/constants/messages"
import { SeverityEnum } from "../../utils/enums/toast.enum"
import { useAppDispatch } from "../../hooks/redux"
import { loading, unLoading } from "src/reducer/auth.reducer"
import { loginSuccess } from "src/reducer/app.reducer"
const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID
const GoogleLoginItem = () => {
  const history = useHistory()
  const dispatch = useAppDispatch()

  const onSuccess = res => {
    dispatch(loading())
    const state = "google_hangouts"
    authService.login(res.tokenId, state).then(result => {
      if (result.data.ok) {
        const authenticateRes = result.data.data
        afterSuccess(authenticateRes)
      } else {
        handleError()
      }
    })
  }

  const afterSuccess = authenticateRes => {
    dispatch(unLoading())
    tokenService.updateLocalAccessToken(authenticateRes.id_token)
    tokenService.updateRefreshToken(authenticateRes.refresh_token)
    tokenService.updateUserId(`${authenticateRes.user_id}`)
    toastHelpers.handleToast(
      dispatch,
      messages.auth.success,
      SeverityEnum.SUCCESS
    )
    dispatch(loginSuccess())
    history.push(PATH.HOME)
  }
  const handleError = () => {
    dispatch(unLoading())
    toastHelpers.handleToast(
      dispatch,
      messages.auth.errorHangout,
      SeverityEnum.ERROR
    )
  }
  return (
    <AppLoginContainer>
      <GoogleLogin
        clientId={clientId!}
        render={renderProps => (
          <LoginButton onClick={renderProps.onClick}>
            Login with Google
          </LoginButton>
        )}
        onSuccess={onSuccess}
        cookiePolicy={"single_host_origin"}
      />
    </AppLoginContainer>
  )
}

export default GoogleLoginItem
