import { Typography } from "@mui/material"
import React from "react"
import {
  AppLoginHeader,
  AppLoginContainer,
  LoginButton,
  LearnMoreButton,
  InstallButton
} from "./Login.styles"
interface Props {
  appItem: AppLoginDataTypes
  learnMoreUrl: string
}
const AppItem = (props: Props) => {
  const { appItem } = props
  return (
    <AppLoginContainer>
      <AppLoginHeader>
        <img src={appItem.image} alt="" width="32" height="32" />
        <Typography sx={{ ml: 2 }} variant="subtitle2">
          {appItem.title}
        </Typography>
      </AppLoginHeader>
      <LoginButton href={appItem.loginUrl}>
        Login with {appItem.title}
      </LoginButton>
      <InstallButton href={appItem.installUrl}>Install</InstallButton>
      <Typography
        sx={{ fontSize: 14, mt: 2 }}
        variant="body2"
        dangerouslySetInnerHTML={{ __html: appItem.description }}
      ></Typography>
      <LearnMoreButton href={props.learnMoreUrl} target="_blank">
        Learn more
      </LearnMoreButton>
    </AppLoginContainer>
  )
}

export default AppItem
