import React, { lazy, Suspense, Fragment } from "react"
import { Route } from "react-router-dom"
import { PATH } from "src/utils/constants/path.constants"
import Loading from "src/components/Loading/Loading"
import AuthResolver from "src/resolvers/AuthResolver"
import { LoginGuard } from "src/guards/Loginguard"

const Login = lazy(() => import("src/pages/Login/Login"))
export default function LoginRoutes() {
  return (
    <Fragment>
      <LoginGuard path={PATH.LOGIN}>
        <Suspense fallback={<Loading />}>
          <Login />
        </Suspense>
      </LoginGuard>
      <Route path={PATH.AUTH} component={AuthResolver} />
    </Fragment>
  )
}
