export const API = {
  AUTH: {
    INSTALL: "auth/install",
    LOGIN: "auth/login",
    SIGN_OUT: "auth/logout",
    REFRESH_TOKEN: "auth/refreshToken"
  },
  admin: {
    org: {
      find_org_name: "admin/org/name?orgName={0}",
      find_org_id: "admin/org/{0}"
    },
    user: {
      find_by_user_name: "admin/user?name={0}"
    },
    standups: {
      find_by_standups_name: "admin/standups/name?name={0}",
      find_standups_id: "admin/standup/{0}"
    }
  }
}
