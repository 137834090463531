import axios, { AxiosInstance } from "axios"
import { authService } from "./services/auth.service"
import { tokenService } from "./services/token.service"
import { ERROR_CODE } from "./utils/constants/error-code"
import { PATH } from "./utils/constants/path.constants"

class AuthInterceptor {
  private instance: AxiosInstance
  constructor() {
    this.instance = axios.create({
      baseURL: process.env.REACT_APP_ENDPOINT,
      headers: {
        "Content-Type": "application/json"
      }
    })
    this.setToken()
    this.checkRequestAndRefreshToken(this.instance)
  }

  getInstance() {
    return this.instance
  }

  private setToken() {
    this.instance.interceptors.request.use(function (config) {
      const token = tokenService.getLocalAccessToken()
      config.headers.Authorization = `Bearer ${token}`
      return config
    })
  }

  private checkRequestAndRefreshToken(instance: AxiosInstance) {
    instance.interceptors.response.use(
      response => {
        const { errorCode } = response.data
        if (errorCode === ERROR_CODE.UNAUTHORIZED) {
          return authService
            .refreshToken()
            .then(rs => {
              if (rs.data.ok) {
                const { token } = rs.data.data
                const config = response.config
                config.headers.Authorization = `Bearer ${token}`
                config.baseURL = process.env.REACT_APP_ENDPOINT
                return instance(config)
              } else {
                localStorage.clear()
                window.location.href = PATH.LOGIN
                return Promise.reject(null)
              }
            })
            .catch(err => {})
        }
        return response
      },
      error => {
        if (error.response) {
          return error.response.data
        } else {
          return Promise.reject(error)
        }
      }
    )
  }
}

export const api = new AuthInterceptor().getInstance()
