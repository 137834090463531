import { createToast } from "src/reducer/toastAuth.reducer"

class ToastHelpers {
  public handleToast = (dispatch, messages, severity) => {
    const toast: ToastParams = {
      messages,
      severity
    }
    dispatch(createToast(toast))
  }
}

export const toastHelpers = new ToastHelpers()
