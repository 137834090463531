import { createSlice } from "@reduxjs/toolkit"
import { TYPES } from "src/utils/constants/types.constants"
import { PageEnum } from "src/utils/enums/page.enum"

const initialState: StateHome = {
  openSidebar: true,
  page: PageEnum.DASHBOARD
}

const homeSlice = createSlice({
  name: TYPES.HOME,
  initialState,
  reducers: {
    openSidebar: state => {
      state.openSidebar = true
    },
    closeSidebar: state => {
      state.openSidebar = false
    },
    updatePage: (state, action) => {
      state.page = action.payload
    }
  }
})

export const { openSidebar, closeSidebar, updatePage } = homeSlice.actions
export default homeSlice.reducer
