import React from "react"
import { Container, ListAppLogin } from "./Login.styles"
import { Alert, Snackbar } from "@mui/material"
import { useAppDispatch, useAppSelector } from "src/hooks/redux"
import { closeToast } from "src/reducer/toastAuth.reducer"
import { Box } from "@mui/system"
import GoogleLoginItem from "./GoogleLogin"
import Loading from "src/components/Loading/Loading"
import { appLoginDatas } from "./AppLoginDatas"
import AppItem from "./AppItem"

const Login = () => {
  const dispatch = useAppDispatch()
  const open = useAppSelector(state => state.authToast.open)
  const messages = useAppSelector(state => state.authToast.messages)
  const severity = useAppSelector(state => state.authToast.severity)
  return (
    <Box
      sx={{
        backgroundColor: "#F6F6F6",
        height: "100vh",
        overflow: "hidden",
        display: "flex",
        alignItems: "center"
      }}
    >
      <Loading />
      <Container>
        <ListAppLogin>
          <AppItem
            appItem={appLoginDatas[0]}
            key={0}
            learnMoreUrl={
              "https://standupalice.freshdesk.com/support/solutions/folders/35000193246"
            }
          ></AppItem>
          <GoogleLoginItem />
        </ListAppLogin>
        <Snackbar
          open={open}
          onClose={() => dispatch(closeToast())}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert
            onClose={() => dispatch(closeToast())}
            severity={severity}
            sx={{ width: "100%" }}
          >
            {messages}
          </Alert>
        </Snackbar>
      </Container>
    </Box>
  )
}

export default Login
