import { Typography } from "@mui/material"
import { styled } from "@mui/system"
import { COLOR } from "src/utils/constants/styles.constant"
// body styles
export const Container = styled("div")(({ theme }) => ({
  display: "flex",
  flexFlow: "column",
  alignItems: "center",
  width: "100%"
}))
export const ListAppLogin = styled("div")(({ theme }) => ({
  maxWidth: 740,
  display: "flex",
  flexFlow: "row wrap",
  justifyContent: "center",
  overflow: "scroll",
  alignItems: "center",
  "::-webkit-scrollbar": {
    display: "none"
  }
}))

export const AppLoginContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexFlow: "column",
  width: 320,
  border: "1px solid rgba(0, 0, 0, 0.12)",
  boxSizing: "border-box",
  margin: `${theme.spacing(1)} ${theme.spacing(1)}`,
  position: "relative",
  alignItems: "center",
  justifyItems: "center",
  borderRadius: 5,
  padding: theme.spacing(2),
  backgroundColor: COLOR.WHITE,
  [theme.breakpoints.down(740)]: {
    width: 343
  }
}))

export const AppLoginHeader = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  width: "100%",
  alignItems: "center"
}))

export const LoginButton = styled("a")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  width: 271,
  height: 45,
  border: `1px solid ${COLOR.PRIMARY}`,
  boxSizing: "border-box",
  borderRadius: 5,
  textDecoration: "none",
  fontSize: 14,
  textAlign: "center",
  color: COLOR.PRIMARY,
  textTransform: "uppercase",
  cursor: "pointer",
  fontWeight: 500,
  letterSpacing: 1.25,
  "&:hover": {
    background: `${COLOR.LIGHT_GREEN}`
  }
}))

export const InstallButton = styled("a")(({ theme }) => ({
  fontSize: 14,
  color: "#000000",
  marginTop: theme.spacing(2),
  textDecoration: "none",
  "&:hover": {
    textDecoration: "underline"
  }
}))

export const LearnMoreButton = styled("a")(({ theme }) => ({
  fontSize: 14,
  color: `${COLOR.SECONDARY}`,
  marginTop: theme.spacing(1),
  cursor: "pointer",
  textDecoration: "none",
  "&:hover": {
    textDecoration: "underline"
  }
}))

export const Header = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  padding: theme.spacing(2)
}))
export const NavLink = styled("a")(({ theme }) => ({
  textDecoration: "none",
  color: "#000000",
  "&:hover": { textDecoration: "underline" }
}))
export const LogoName = styled(Typography)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  [theme.breakpoints.down(740)]: {
    display: "none"
  }
}))
