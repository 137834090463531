import slackLogo from "../../assets/image/_x33_06-slack.png"

const environmentLogin = {
  slack: {
    redirect_uri: process.env.REACT_APP_SLACK_REDIRECT_URI,
    scope_auth: process.env.REACT_APP_SLACK_SCOPE_AUTH,
    client_id: process.env.REACT_APP_SLACK_CLIENT_ID,
    scope_install: process.env.REACT_APP_SLACK_SCOPE_INSTALL
  }
}
export const appLoginDatas: AppLoginDataTypes[] = [
  {
    image: slackLogo,
    title: "Slack",
    loginUrl: `https://slack.com/oauth/v2/authorize?user_scope=${environmentLogin.slack.scope_auth}&client_id=${environmentLogin.slack.client_id}&redirect_uri=${environmentLogin.slack.redirect_uri}&state=authv2`,
    installUrl: `https://slack.com/oauth/v2/authorize?scope=${environmentLogin.slack.scope_install}&client_id=${environmentLogin.slack.client_id}&redirect_uri=${environmentLogin.slack.redirect_uri}&state=installv2`,
    description: ""
  }
]
