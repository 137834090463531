import { API } from "src/utils/constants/api.constants"
import { api } from "../auth.interceptor"
import axios from "axios"
import { tokenService } from "./token.service"

class AuthService {
  install = (code: string, state: string): Promise<Res<AuthenticateRes>> => {
    const apiUrl = API.AUTH.INSTALL
    const redirectUri = process.env.REACT_APP_SLACK_REDIRECT_URI
    return api.post(apiUrl, {
      code,
      state,
      redirectUri: redirectUri
    })
  }

  login = (code: string, state: string): Promise<Res<AuthenticateRes>> => {
    const apiUrl = API.AUTH.LOGIN
    const redirectUri = process.env.REACT_APP_SLACK_REDIRECT_URI
    return api.post(apiUrl, {
      code,
      state,
      redirectUri: redirectUri
    })
  }

  refreshToken = () => {
    const apiUrl = API.AUTH.REFRESH_TOKEN
    const refreshTokenApi = axios.create({
      baseURL: process.env.REACT_APP_ENDPOINT,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${tokenService.getLocalRefreshToken()}`
      }
    })
    return refreshTokenApi.get(apiUrl)
  }

  signOut = (): Promise<Res<any>> => {
    const apiUrl = API.AUTH.SIGN_OUT
    return api.get(apiUrl)
  }
}
export const authService = new AuthService()
