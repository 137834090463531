import { combineReducers } from "redux"
import appReducer from "./app.reducer"
import authReducer from "./auth.reducer"
import authToastReducer from "./toastAuth.reducer"
import homeReducer from "./home.reducer"

const rootReducer = combineReducers({
  app: appReducer,
  auth: authReducer,
  authToast: authToastReducer,
  home: homeReducer
})

export default rootReducer
