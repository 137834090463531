import React, { Fragment } from "react"
import LoginRoutes from "./LoginRoutes"
import HomeRoutes from "./HomeRoutes"
export default function Routes() {
  return (
    <Fragment>
      <LoginRoutes />
      <HomeRoutes />
    </Fragment>
  )
}
